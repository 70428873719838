.intro {
  height: 100%;
}

#bg-img {
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: url("../Assets/background.webp") no-repeat center center fixed, url("../Assets/bg.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

#mask {
  position: static;
  background-color: rgba(0, 0, 0, 0.6);
  height: 100%;
}
